import { env } from '../environments/environment';
import { translationService } from './translationsService';
import axios from 'axios';
import { setTitle } from '../interceptors/setTitle';
import { checkCSRFToket } from '../interceptors/checkCSRFToket';

const apiDomain = env.dynamic['REACT_APP_DOMAIN_URL'] ?? '/';

const abortControllers = new Object();

export const getApiDomain = (): string => apiDomain;

const CSRFToken = ((env) => {
  let token = '';
  if (env.dynamic['REACT_APP_CSRF']) {
    const metaElem = document.querySelectorAll('[name="csrf-token"]');
    token = metaElem != null ? metaElem[0].getAttribute('content') : '';
  }
  return token;
})(env);

const pageId = (() => {
  const body = document.querySelectorAll('body');
  const id = body != null ? body[0].getAttribute('data-page-id') : '';
  return id;
})();

const domainWithPrefix = apiDomain + env.fixed.apiPrefix;

axios.defaults.baseURL = domainWithPrefix;
axios.defaults.headers.common = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '0',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  CSRFToken: CSRFToken,
  PageId: pageId
};

axios.interceptors.response.use(setTitle, checkCSRFToket);

export const getData = async <T = any>(url = '', abortName?: string): Promise<T> => {
  const response = await axios.get(url, {
    headers: {
      Culture: translationService.getCulture()
    },
    signal: onAbortController(abortName)
  });
  return (await response.data) as T;
};

export const postData = async <T = any>(url = '', data = {}): Promise<T> => {
  const response = await axios.post(url, data, {
    headers: {
      Culture: translationService.getCulture()
    }
  });
  if (response.status === 200) {
    return (await response.data) as T;
  } else {
    return Promise.reject(response);
  }
};

export const putData = async (url = '', data = {}) => {
  const response = await axios.put(url, data, {
    headers: {
      Culture: translationService.getCulture()
    }
  });
  if (response.status === 200) {
    return await response.data;
  } else {
    return Promise.reject(response);
  }
};

export const getFileData = (url = '') => {
  return axios
    .get(url, {
      headers: {
        Culture: translationService.getCulture()
      },
      responseType: 'blob'
    })
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
      return Promise.reject(response);
    });
};

const onAbortController = (abortName: string) => {
  if (abortName) {
    if (Object.keys(abortControllers).length === 0 || !(abortName in abortControllers)) {
      abortControllers[abortName] = new AbortController();
      return abortControllers[abortName].signal;
    } else {
      abortControllers[abortName].abort();
      abortControllers[abortName] = new AbortController();
      return abortControllers[abortName].signal;
    }
  }
  return null;
};
