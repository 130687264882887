const fixed = {
  apiPrefix: 'api/v2/',
  translation: 'translations/get/',
  reusableContent: 'translations/reusableContent/',
  tareWeightGet: 'tareweight/get/',
  eContainer: {
    get: 'econtainer/get/',
    getPdf: 'econtainer/getPdf/'
  },
  stickyBarGet: 'stickybar/get?culture=',
  scheduleByRoute: {
    portInLandsGet: 'scheduleByRoute/GetPortsInLands?query=',
    onSearch: 'schedules/point-to-point?',
    getResult: 'scheduleByRoute/getResult',
    getAdditionalData: 'scheduleByRoute/GetAdditionalData',
    getEzQuoteData: 'scheduleByRoute/GetEzQuote',
    getPortByCode: 'scheduleByRoute/GetPortByCode'
  },
  scheduleByLine: {
    tradesGet: 'scheduleByLine/GetTrades',
    linesGet: 'scheduleByLine/GetLines?tradeCode=',
    onSearch: 'schedules/schedule-by-line?',
    getResult: 'scheduleByLine/GetResult',
    getDetails: 'scheduleByLine/GetDetails'
  },
  scheduleByVessel: {
    vesselsGet: 'scheduleByVessel/GetVessels?query=',
    onSearch: 'schedules/schedule-by-vessel?',
    renderResult: 'scheduleByVessel/RenderResult',
    externalItems: 'scheduleByVessel/GetExternalServiceItems'
  },
  scheduleByPort: {
    portsGet: 'scheduleByPort/GetPortsByCode?',
    onSearch: 'schedules/schedule-by-port?',
    getResult: 'scheduleByPort/GetResult'
  },
  scheduleContact: {
    getContacts: 'scheduleContact/getContactsData'
  },
  countriesGet: 'form/GetCountry?query=',
  navbar: {
    getTopMenu: 'navbar/top-menu',
    getTopCountries: 'navbar/countries',
    getMainMenu: 'navbar/main-menu',
    getTopLanguages: 'navbar/languages',
    getTranslations: 'navbar/translations',
    getBranchUrl: 'navbar/contact-branch',
    getCountryContact: 'navbar/country-contacts',
    getCountryCustomers: 'navbar/country-customers'
  },
  storageCertificate: {
    gloabl: 'storageCertificate/getcertificate/',
    india: 'storageCertificate/getindiacertificate/'
  },
  trades: 'trade/GetTrades',
  expectedVessels: {
    getExpectedVessels: 'expectedVessels/get'
  },
  statusOfVessel: 'statusOfVessels/Get',
  ecoData: {
    getData: 'ecodata/search',
    getPdf: 'ecodata/getPdf'
  },
  careers: {
    get: 'careers',
    descriptionLink: '/about-zim/careers/'
  },
  etaForVessels: {
    get: 'etaForVessels/get',
    getLazy: 'etaForVessels/getLazy'
  },
  phonePrefixGet: 'form/GetPhonePrefixByCountry',
  unsubscribe: {
    sendCode: 'unsubscribe/sendCode',
    checkCode: 'unsubscribe/checkCode',
    getChannel: 'unsubscribe/GetChannel',
    update: 'unsubscribe/Update',
    getSpecificCargo: 'unsubscribe/GetSpecificCargo',
    unsubscribeSpecificCargo: 'unsubscribe/UnsubscribeSpecificCargo'
  },
  billOfLading: {
    getCountries: 'billOfLading/getCountries',
    getData: 'billOfLading/getData/'
  },
  captcha: 'simple-captcha-endpoint.ashx',
  demurrage: {
    getCountries: 'demurrage/getCountries?query=',
    getCountry: 'demurrage/getCountries?CountryCode=',
    getPortsByCountry: 'demurrage/getPortsByCountry',
    getFilters: 'demurrage/getFilters',
    getResults: 'demurrage/GetResult'
  },
  trackShipment: {
    getTracing: 'trackShipment/GetTracing',
    sendNonDsa: 'trackShipment/NonDsaSubmit'
  },
  usStatus: {
    render: 'usstatus/render',
    getContainerDetails: 'usstatus/getContainerDetails',
    getTracingDetails: 'usstatus/getTracingDetails'
  },
  localCharges: {
    getLocalPorts: 'localcharges/GetLocalPorts',
    renderSearch: 'localcharges/RenderSearch'
  }
};

export { fixed };
