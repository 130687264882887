import { Translations, CultureTranslations } from '../types/dto/Translations';
import { getData } from './crud';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { typeGuard } from '../helpers/typeguard';
import { ErrorResponse } from '../types/dto/ErrorResponse';
import { ReusableContent } from '../types/translations/ReusableContent';
import { env } from '../environments/environment';
import moment from 'moment';
import 'moment/min/locales';

const FALLBACK_REUSABLE_CONTENT = {
  fallback:
    '<h2>Hi there . . .</h2>\n<p>The information you requested is currently unavailable. Please contact your local ZIM office.</p>\n<p><span class="small"> </span></p>'
};

class TranslationService {
  private _culture;
  private _reusableContent: ReusableContent = null;
  private _language;

  getCulture = () => {
    if (this._culture) {
      return this._culture;
    } else {
      this._culture = document.getElementById('main')?.getAttribute('data-culture') ?? 'en-US';
      return this._culture;
    }
  };

  getLanguage = () => {
    if (this._language) {
      return this._language;
    } else {
      this._language = document.querySelector('html').getAttribute('lang') ?? 'en';
      return this._language;
    }
  };

  getReusableContent = async (): Promise<ReusableContent> => {
    if (!this._culture) {
      this.getCulture();
    }

    if (!this._reusableContent) {
      try {
        const content = await getData<ReusableContent>(
          `${env.fixed.reusableContent}${this._culture}`
        );

        this._reusableContent = { ...FALLBACK_REUSABLE_CONTENT, ...content };
      } catch {
        this._reusableContent = FALLBACK_REUSABLE_CONTENT;
      }
    }

    return this._reusableContent;
  };
  getCultureTranslations = async (): Promise<CultureTranslations> => {
    if (!this._culture) {
      this.getCulture();
    }

    const sessionTranslations = sessionStorage.getItem(`translations-${this._culture}`);

    const translationsResult: CultureTranslations = {
      lang: this._culture,
      translations: null
    };

    if (sessionTranslations) {
      return {
        ...translationsResult,
        translations: JSON.parse(sessionTranslations)
      };
    }

    const translations = await getData<Translations | ErrorResponse>(
      `${env.fixed.translation}${this._culture}`
    );

    if (typeGuard.isOfType<ErrorResponse>(translations, ['status', 'title', 'type'])) {
      throw new Error("Couldn't load translations");
    }

    sessionStorage.setItem(`translations-${this._culture}`, JSON.stringify(translations));

    return {
      ...translationsResult,
      translations
    };
  };

  tryInitLocalization = async (): Promise<boolean> => {
    try {
      const cultureTranslations = await this.getCultureTranslations();

      await i18next.use(initReactI18next).init({
        lng: cultureTranslations.lang,
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en-US',
        resources: {
          [cultureTranslations.lang]: {
            translation: cultureTranslations.translations
          }
        }
      });
      moment.locale(this.getCulture());
      return true;
    } catch (error) {
      console.log('Error loading translations: ', error);
      return false;
    }
  };
}

export const translationService = new TranslationService();
