import './index.scss';
import { translationService } from './services/translationsService';

const deployApp = async () => {
  const isSuccess = await translationService.tryInitLocalization();

  if (process.env.NODE_ENV === 'development') {
    const dev = await import('./dev');

    dev.deployDevApp(isSuccess);
    return;
  }

  const prod = await import('./prod');

  prod.deployProdApp(isSuccess);
};

deployApp();
