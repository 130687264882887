export const checkCSRFToket = (error: any) => {
  if (error.response.status === 417) {
    const event = new CustomEvent('csrftoken-refresh', {
      detail: '417 error',
      bubbles: true,
      cancelable: true
    });

    window.dispatchEvent(event);
  }
  return Promise.reject(error);
};
